<template>
    <div class="about-container">
        <img class="about-logo" src="../assets/logo.png" />
        <div class="about-text">
        <h1>About the team behind Beholder Maps</h1>
        <p>We understand how much time and effort it can take as a DM to go creating your own battlemaps for your sessions.</p>
        <p>We're here to help you out.</p>
        <p>Our goal is to help every Dungeon Master in the world take time out of prepping the tedious parts of a session, so that you have more time to prep the fun parts of a session. </p>
    </div>
  </div>
</template>

<script>
export default {
    name: 'About',
}
</script>


<style scoped>
h1 {
  font-size: 32px;
}

.about-container {
  height: 40vh;
  width: 100%;
  position: relative;
  display: inline-block;
}
  .about-logo {
    position: absolute;
    height: 100%;
    right: 0;
    filter: invert(95%) sepia(7%) saturate(202%) hue-rotate(187deg) brightness(90%) contrast(89%);
  }
  .about-text {
    z-index: 1;
    min-width: 400px;
    max-width: 60%;
    position: absolute;
    top: 25%;
  }
  .about-text > h1 {
    text-align: start;
  }
  .about-text > p {
    margin: 20px 0 20px 0;
    text-align: start;
  }

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 1200px) {
  .about-container {
    height: 35vw;
  }
    .about-text {
      top: 15%;
    }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) { 
  .about-container {
    height: 40vw;
  }
    .about-text {
      top: 10%;
    }
    .about-logo {
      max-width: 100%;
      height: auto;
    }
}

/* Landscape phones and down */
@media (max-width: 480px) {
  h1 {
    font-size: 24px;
  }
  p {
    font-size: 14px;
  }

  .about-container {
    height: 60vw;
  }
    .about-text {
      min-width: 100%;
      top: 0;
    }
}

</style>